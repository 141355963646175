import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import toneImg from '../img/icons/2.png';
import ttwoImg from '../img/icons/6.png';
import {toast,  Toaster } from 'react-hot-toast';

function MainContact({ conImg }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    business_email: "",
    phone: "",
    list_needed: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      business_email: formData.business_email,
      phone: formData.phone,
      list_needed: formData.list_needed,
      message: formData.message,
    };

    try {
      await emailjs.send(
        "service_m6dal7q",// "your_service_id",  // Replace with your EmailJS service ID
        "template_hnyb2y8",// "your_template_id", // Replace with your EmailJS template ID
        templateParams,
        "m-pdBJ7M_iV3DovSN",// "your_public_key"   // Replace with your EmailJS public key
      );
      toast.success("Message Sent Successfully!");
      setFormData({
        name: "",
        email: "",
        business_email: "",
        phone: "",
        list_needed: "",
        message: "",
      });
    } catch (error) {
      console.error("Failed to send email:", error);
      toast.error("Error sending email.");
    }
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false} />
      <div className='contact-area pd-bottom-120'>
        <div className='container'>
          <div className='contact-inner-1'>
            <img className='top_image_bounce animate-img-1' src={toneImg} alt='img' />
            <img className='top_image_bounce animate-img-2' src={ttwoImg} alt='img' />
            <div className='row pd-top-90'>
              <div className='col-lg-8' data-aos='fade-right' data-aos-delay='200' data-aos-duration='1500'>
                <img className='w-100' src={conImg} alt='img' />
              </div>
              <div className='col-lg-4 wow animated fadeInRight' data-aos='fade-left' data-aos-delay='200' data-aos-duration='1500'>
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>GET IN TOUCH</h6>
                  <h2 className='title'>Reach our expert professionals</h2>
                  <form className="mt-4" onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="email" name="business_email" placeholder="Business Email" value={formData.business_email} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="single-input-inner style-border">
                          <input type="text" name="phone" placeholder="Your Phone" value={formData.phone} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <input type="text" name="list_needed" placeholder="Type Of List Needed" value={formData.list_needed} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="single-input-inner style-border">
                          <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} required />
                        </div>
                      </div>
                      <div className="col-12">
                        <button type="submit" className="btn btn-black mt-0 w-100 border-radius-5">
                          Submit now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainContact;
